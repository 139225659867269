<template>
	<b-container fluid>
		<b-row>
			<Breadcrumb titulo="TEMPLATES DE MENSAGEM"
						:items="breadcrumb"
						class="breadcrumb" />
		</b-row>
		<b-row class="mx-0 my-1">
			<b-col class="templates-container p-3">
				<b-row>
					<b-col>
						<input type="text" placeholder="Buscar template" v-model="search.query" />
						<select v-model="search.setorSelecionado">
							<option :value="null">Setor</option>
							<option v-for="setor in search.setores" :value="setor.Id">{{setor.Nome}}</option>
						</select>
						<select v-model="search.usuarioSelecionado">
							<option :value="null">Atendente</option>
							<option v-for="usuario in search.usuarios" :value="usuario.Id">{{usuario.Nome}}</option>
						</select>
						<b-button @click="consultarTemplatesMensagem" class="box-cliente rounded-0">Filtrar</b-button>
					</b-col>
					<b-col cols="12" md="auto">
						<b-button @click="cadastrarTemplateMensagem" class="box-cliente rounded-0">Cadastrar template</b-button>
					</b-col>
				</b-row>
				<b-row class="h-100">
					<b-col class="templates-container-body app-scroll-custom">
						<b-table striped :fields="fields" :items="templates">
							<template #cell(Setor)="data">
								<span v-if="data.value">{{data.value}}</span>
								<span v-else>Todos</span>
							</template>
							<template #cell(Usuario)="data">
								<span v-if="data.value">{{data.value}}</span>
								<span v-else>Todos</span>
							</template>
							<template #cell(Template)="data">
								<span v-html="data.value"></span>
							</template>
							<template #cell(Hashtags)="data">
								<div v-if="data.value" v-for="tag in data.value.split(' ')">{{tag}}</div>
							</template>
							<template #cell(Inclusao)="data">
								{{moment(data.value).format("DD/MM/YYYY HH:mm")}}
							</template>
							<template #cell(actions)="data">
								<i @click="editarTemplateMensagem(data.item)"
								   title="Editar template"
								   class="fas fa-pen mr-3"></i>
								<i @click="excluirTemplate(data.item)"
								   title="Excluir template"
								   class="fas fa-trash"></i>
							</template>
							<template #cell()="data">
								{{data.value}}
							</template>
						</b-table>
						<center v-if="templates && templates.length == 0 && !search.isSearching && !search.catchError">
							Nenhum template disponível para os filtros definidos.
						</center>
						<center v-if="search.isSearching" class="text-cliente">
							<b-spinner class="mr-2" small></b-spinner>
							Carregando...
						</center>
						<center v-if="search.catchError" class="text-danger">
							Houve um erro no carregamento de templates de mensagem.
						</center>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
		<b-modal id="gerenciar-template-mensagem"
				 size="lg"
				 :title="`${template.Id ? 'Editar' : 'Cadatrar novo'} template de mensagem`">
			<div class="data-field">
				<label for="template-mensagem-titulo">Título</label>
				<input type="text"
					   id="template-mensagem-titulo"
					   v-model="template.Titulo" />
			</div>
			<div class="data-field">
				<label>Mensagem</label>
				<HtmlEditor id="template-mensagem-template" :dynamicArea="false" :varsList="varsList" v-model="template.Template" />
			</div>
			<b-container class="p-0">
				<b-row>
					<b-col>
						<div class="data-field">
							<label for="template-mensagem-setor">Setor</label>
							<select id="template-mensagem-setor" v-model="template.SetorId">
								<option :value="null">Todos</option>
								<option v-for="setor in search.setores" :value="setor.Id">
									{{setor.Nome}}
								</option>
							</select>
						</div>
					</b-col>
					<b-col>
						<div class="data-field">
							<label for="template-mensagem-usuario">Usuário</label>
							<select id="template-mensagem-usuario" v-model="template.UsuarioId">
								<option :value="null">Todos</option>
								<option v-for="usuario in search.usuarios" :value="usuario.Id">
									{{usuario.Nome}}
								</option>
							</select>
						</div>
					</b-col>
				</b-row>
			</b-container>
			<div class="data-field">
				<label for="template-mensagem-tags">Hashtags (separar por espaço)</label>
				<input type="text"
					   id="template-mensagem-tags"
					   v-model="template.Tags" />
			</div>
			<template #modal-footer="{ cancel }">
				<b-button variant="light"
						  @click="cancel()"
						  class="rounded-0"
						  :disabled="isSaving">Cancelar</b-button>
				<b-button variant="success"
						  @click="salvarTemplateMensagem"
						  class="rounded-0"
						  :disabled="isSaving || !templateIsValid">
					<span v-if="isSaving">
						<b-spinner small class="mr-1"></b-spinner>
						Processando...
					</span>
					<span v-else>Confirmar</span>
				</b-button>
			</template>
		</b-modal>
	</b-container>
</template>

<script>
	import axios from "axios";
	import moment from "moment";
	import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
	import HtmlEditor from "@/components/inputs/HtmlEditor";

	export default {
		name: "TemplatesMensagemPage",
		components: {
			Breadcrumb,
			HtmlEditor
		},
		data() {
			return {
				breadcrumb: [
					{
						id: "1",
						classe: "fas fa-cogs",
						texto: "Cadastros Básicos",
						link: "/cadastros-basicos",
						cor: "#259cd3"
					},
					{
						id: "2",
						classe: "fa fa-hashtag",
						texto: "Templates de Mensagem",
						link: "/templates-mensagem",
						cor: "#74e4ff"
					}
				],
				fields: [
					{ key: "Titulo", label: "Título", sortable: true },
					{ key: "Setor", label: "Setor", sortable: true },
					{ key: "Usuario", label: "Usuário", sortable: true },
					{ key: "Template", label: "Template", sortable: true },
					{ key: "Hashtags", label: "Hashtags", sortable: true },
					{ key: "Inclusao", label: "Data de Inclusão", sortable: true },
					{ key: "actions", label: "Ações" }
				],
				templates: [],
				template: {
					Id: null,
					Titulo: null,
					SetorId: null,
					UsuarioId: null,
					Template: null,
					Tags: null
				},
				isSaving: false,
				search: {
					query: null,
					setorSelecionado: null,
					usuarioSelecionado: null,
					setores: [],
					usuarios: [],
					isSearching: false,
					catchError: false
				},
				varsList: [
					{ value: "{CODIGO_ATENDIMENTO}", label: "Código do Atendimento" },
					{ value: "{CLIENTE_NOME}", label: "Nome" },
					{ value: "{CLIENTE_EMAIL}", label: "E-mail" },
					{ value: "{CLIENTE_TELEFONE}", label: "Telefone" },
					{ value: "{CLIENTE_CNPJ_CPF}", label: "CPF / CNPJ" }
				]
			};
		},
		computed: {
			textoDoTemplate() {
				if (!this.template || !this.template.Template) return "";
				let span = document.createElement("span");
				span.innerHTML = this.template.Template;
				return span.textContent ?? span.innerText ?? "";
			},
			templateIsValid() {
				if (!this.template) return false;
				if (!this.template.Titulo?.trim()) return false;
				if (!this.textoDoTemplate.trim()) return false;
				if (!this.template.Tags?.trim()) return true;
				let tags = this.template.Tags.split(" ").filter(tag => tag.trim());
				if (tags.length != tags.filter(tag => tag.startsWith("#") && tag.length > 1).length) return false;
				return true;
			}
		},
		methods: {
			consultarTemplatesMensagem() {
				this.templates = [];
				this.search.catchError = false;
				this.search.isSearching = true;
				return axios.get(`/api/templates-mensagem`, {
					params: {
						query: this.search.query,
						setorId: this.search.setorSelecionado,
						usuarioId: this.search.usuarioSelecionado
					}
				}).then(response => {
					this.search.isSearching = false;
					this.templates = response.data;
				}).catch(() => {
					this.search.isSearching = false;
					this.search.catchError = true;
				})
			},
			cadastrarTemplateMensagem() {
				this.template.Id = null;
				this.template.Template = null;
				this.template.Titulo = null;
				this.template.SetorId = null;
				this.template.UsuarioId = null;
				this.template.Tags = null;
				this.$bvModal.show("gerenciar-template-mensagem");
			},
			editarTemplateMensagem(template) {
				this.template.Id = template.Id;
				this.template.Template = template.Template;
				this.template.Titulo = template.Titulo;
				this.template.SetorId = template.SetorId ?? null;
				this.template.UsuarioId = template.UsuarioId ?? null;
				this.template.Tags = template.Hashtags;
				this.$bvModal.show("gerenciar-template-mensagem");
			},
			salvarTemplateMensagem() {
				this.isSaving = true;
				axios[this.template.Id ? "put" : "post"](`/api/templates-mensagem`, this.template).then(response => {
					this.isSaving = false;
					this.$bvToast.toast("Template salvo com sucesso.", {
						title: "Concluído",
						toaster: "b-toaster-top-right",
						solid: true,
						variant: "success",
						appendToast: false,
						autoHideDelay: 3000
					});
					if (!this.template.Id) this.template.Id = response.data;
					this.consultarTemplatesMensagem();
					this.$bvModal.hide("gerenciar-template-mensagem");
				});
			},
			excluirTemplate(template) {
				this.$bvModal.msgBoxConfirm("O template de mensagem será excluído. Deseja continuar?", {
					title: "Atenção",
					size: "md",
					buttonSize: "sm",
					okVariant: "danger",
					okTitle: "Confirmar",
					cancelTitle: "Cancelar"
				}).then(confirm => {
					if (confirm) axios.delete(`/api/templates-mensagem/${template.Id}`).then(() => {
						this.$bvToast.toast("Template excluído com sucesso.", {
							title: "Concluído",
							toaster: "b-toaster-top-right",
							solid: true,
							variant: "success",
							appendToast: false,
							autoHideDelay: 3000
						});
						this.templates.splice(this.templates.indexOf(template), 1);
					});
				});
			}
		},
		created() {
			this.moment = moment;
			axios.get(`/api/usuario/lista-basica`).then(response => {
				this.search.usuarios = response.data;
			});
			axios.get(`/api/setor/lista-basica`).then(response => {
				this.search.setores = response.data;
			});
			this.consultarTemplatesMensagem();
		}
	}
</script>
<style scoped>
	.breadcrumb {
		background-color: #74e4ff;
	}

	.templates-container {
		background-color: var(--cinza-2);
		margin-bottom: 70px;
		min-height: calc(100vh - 150px);
	}

		.templates-container input {
			width: 15vw;
			margin-right: 15px;
		}

		.templates-container select {
			width: 10vw;
			margin-right: 15px;
		}

	.templates-container-body {
		background-color: #fff;
		height: calc(100% - 45px);
		margin: 10px 15px;
		padding: 0;
		font-size: 12px;
		cursor: default;
		padding: 0 3px;
	}

		.templates-container-body i.fas {
			cursor: pointer;
		}

	#template-mensagem-template {
		padding: 0;
		font-size: 12px;
	}
</style>

<style>
	#template-mensagem-template .html-editor-variable {
		border: 1px var(--cor-primaria-cliente) solid;
		color: var(--cor-primaria-cliente);
		padding: 2px 5px;
		font-size: 10px;
	}

		#template-mensagem-template .html-editor-variable:before {
			content: "\f1c0";
			font-family: "Font Awesome 5 Free";
			font-weight: 600;
			font-size: 1.2em;
			margin-right: 3px;
		}
</style>